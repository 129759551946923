Harrison.Widgets.TopMenu = Harrison.Abstract.View.extend({
  el: '#main-nav',
  initialize: function () {
    this.search = new Harrison.Widgets.HeaderSearch();

    this.bindEvents();
  },
  bindEvents: function () {
    var _this = this;

    this.$el.find('[data-toggle=collapse]').on('click', function (e) {
      e.preventDefault();

      _this.toggleMenu(jQuery(this));

      return false;
    });

    this.$el.find('.search-trigger').on('click', function(){
      _this.search.show();
    });
  },
  toggleMenu: function (toggle) {
    var target = jQuery(toggle.attr('data-target'));
    if (!target.hasClass('show')) {
      target.addClass('show').css({
        right: -target.width()
      }).animate({
        right: 0
      }, function () {
        jQuery('body').css('overflow', 'hidden');
        target.css('overflow', 'auto');
      });
    }
    else {
      jQuery('body').css('overflow', 'visible');
      target.css({
        overflow: 'hidden'
      }).animate({
        right: -target.width()
      }, function () {
        target.removeClass('show');
      });
    }
  }
});

Harrison.Widgets.HeaderSearch = Harrison.Abstract.View.extend({
  el: '.header-search-box',
  initialize: function(){

  },
  show: function(){
    this.$el.fadeIn('fast');
    this.$el.find('input[name=q]').focus();
  }
});